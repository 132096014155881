import moment, { type Moment } from 'moment-timezone';
import 'moment/dist/locale/ru';

/** Helper для работы с датой и временем. */
class dateTimeHelper {
    static readonly  MoscowTimeZone = 'Europe/Moscow';

    constructor() {
        moment.locale('ru');
    }

    /**
     * Возвращает дату и время в часовом поясе Москвы.
     * @param date Дата и время.
     * @returns Дата и время в часовом поясе Москвы.
     */
    public toMoscowMomentDateTime = (date: Moment) : Moment => {
        return date.tz(dateTimeHelper.MoscowTimeZone);
    }
};

export default new dateTimeHelper();